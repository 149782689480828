import React, { useEffect, useState } from "react";
import { DeviceDetail, Layout } from "../components/molecules";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { structureData } from "../utils/FormatData";
import { HomeStyle } from "./Pages.style";
import {
  getDeviceDetailById,
  initiateDeviceUpdatePost,
} from "../Redux/Zte/Device/DeviceActions";
import { IoIosArrowBack } from "react-icons/io";
import Loader from "../components/atoms/Loader";
import SendNotificationModal from "../components/molecules/SendNotificationModal";
import DeviceAssignmentModal from "../components/molecules/DeviceAssignmentModal";
import MoveDeviceModal from "../components/molecules/MoveDeviceModal";
import UpdateShareStatusModal from "../components/molecules/UpdateShareStatusModal";

const DeviceDetails = () => {
  let userRole = JSON.parse(localStorage.getItem("tkn"))?.role[0];
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [openDeviceAssignmentModal, setOpenDeviceAssignmentModal] = useState(false);
  const [openMoveDeviceModal, setOpenMoveDeviceModal] = useState(false);
  const [openSetShareStatusModal, setOpenSetShareStatusModal] = useState(false)

  const { deviceId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDeviceDetailById(deviceId));
  }, [dispatch, deviceId]);

  const handleOpen = () => {
    setOpenNotificationModal(true);
  };

  const { deviceDetail, fetchingDeviceDetail } = useSelector(
    (state) => state.devices
  );
  const isShared = deviceDetail?.isShared;
  const handleOpenDeviceAssignment = () => {
    setOpenDeviceAssignmentModal(true);
  };
  const handleOpenMoveDevice = () => {
    setOpenMoveDeviceModal(true);
  };
  const handleOpenShareStatusModal = () => {
    setOpenSetShareStatusModal(true)
  }
 
  const updateDeviceData = {
    deviceNames: [deviceDetail.deviceName],
  };
 
  const handleUpdateDevice = () => {
    dispatch(initiateDeviceUpdatePost(updateDeviceData));
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };

  return (
    <Layout>
      <SendNotificationModal
        open={openNotificationModal}
        setOpen={setOpenNotificationModal}
      />
      <DeviceAssignmentModal
        open={openDeviceAssignmentModal}
        setOpen={setOpenDeviceAssignmentModal}
      />
      <MoveDeviceModal
        open={openMoveDeviceModal}
        setOpen={setOpenMoveDeviceModal}
      />
       <UpdateShareStatusModal
        open={openSetShareStatusModal}
        setOpen={setOpenSetShareStatusModal}
        isShared={isShared}
      />
      <HomeStyle>
        <button className="back-btn" onClick={() => navigate(-1)}>
          <IoIosArrowBack />
          <p>Back</p>
        </button>

        {!fetchingDeviceDetail ? (
          <div>
            <div className="buttons-container">
              <button className="send-notification-button" onClick={handleOpen}>
                Send Notification
              </button>
              {userRole === "Administrator" && (
                <>
                  <button
                    className="send-notification-button"
                    onClick={handleOpenDeviceAssignment}
                  >
                    Device Assignment
                  </button>
                  <button
                    className="send-notification-button"
                    onClick={handleOpenShareStatusModal}
                  >
                    {isShared ? "Unshare" : "Share"}
                  </button>{" "}
                  <button
                    className="send-notification-button"
                    onClick={handleUpdateDevice}
                  >
                    Update
                  </button>{" "}
                  <button
                    className="send-notification-button"
                    onClick={handleOpenMoveDevice}
                  >
                    Move
                  </button>
                </>
              )}
            </div>
            <DeviceDetail
              deviceDetail={structureData("deviceDetail", deviceDetail)}
            />
          </div>
        ) : (
          <Loader />
        )}
      </HomeStyle>
    </Layout>
  );
};

export default DeviceDetails;
