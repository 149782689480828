import Modal from "@mui/material/Modal";
import { ModalFormWrapper } from "../../pages/Pages.style";
import { ReactComponent as CloseBtn } from "../../assets/icons/close.svg";
import { DeviceAssignmentForm } from "./form";
import { useState, useEffect } from "react";

const DeviceAssignmentModal = ({ open, setOpen}) => {
  const [isSuccessful, setIsSuccessful] = useState(false);
  const handleClose = () => {
    setOpen && setOpen(false);
    setIsSuccessful(true);
  };
  useEffect(() => {
    setIsSuccessful(false);
  }, [isSuccessful]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalFormWrapper>
        <div className="top">
          <h2>Device Assignment</h2>
          <div className="close">
            <CloseBtn onClick={handleClose} />
          </div>
        </div>
        <div className="edit-container">
          <DeviceAssignmentForm setOpen={setOpen} />
        </div>
      </ModalFormWrapper>
    </Modal>
  );
};

export default DeviceAssignmentModal;
