import { styled } from "styled-components";
import Pagination from "./Pagination";

const TableStyle = styled.table`
  /* border-style: none; */
  border-radius: 0.25rem;
  border-collapse: collapse;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  max-height: 500px;
  overflow-x: scroll;
  overflow-y: auto;
  margin-top: 2rem;
  white-space: nowrap;
  width: 100%;
  border-spacing: 0;
  /* border-collapse: separate; */
  /* border-radius: 10px; */
  /* border: 1px solid #62646d;  */
  tfoot {
    position: sticky;
    bottom: 0;
    z-index: 2;
  }
  tr {
    background-color: #f6f6f6;
    &:nth-of-type(even) {
      background-color: #e9e9e9;
    }
  }
  td {
    padding: 0.3rem 1.5rem;
  }
  thead {
    position: sticky;
    top: 0;
    z-index: 2;
    tr {
      background-color: #96c115;
      background-color: #62646d;
      font-weight: 900;
      color: #ffffff;
      th {
        padding: 0.8rem 1.5rem;
        text-align: left;
      }
    }
  }
  .centered {
    /* display: flex; */
    justify-content: center;
  }
`;
const Table = ({
  data,
  headers,
  loading,
  currentPage,
  pageSize,
  setCurrentPage,
  handleRowClick,
  totalCount,
  type,
}) => {
  // const currentTableData = useMemo(() => {
  // 	const firstPageIndex = (currentPage - 1) * pageSize;
  // 	const lastPageIndex = firstPageIndex + pageSize;
  // 	return data && data?.length > pageSize
  // 		? data?.slice(firstPageIndex, lastPageIndex)
  // 		: data;
  // }, [currentPage, data]);
  // useEffect(()=>{
  // 	currentTableData();
  // },[currentPage])
  return (
    <TableStyle>
      <thead>
        <tr>
          {headers?.map((header, index) =>
            header === "Service" ? (
              <th colSpan={4} key={index}>
                {" "}
                <div>{header}</div>
              </th>
            ) : (
              <th key={index}>
                <div>{header}</div>
              </th>
            )
          )}
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan={9} className="centered">
              <p>Loading...</p>
            </td>
          </tr>
        ) : data === "" ? (
          <tr>
            <td colSpan={9} className="centered">
              <p>No active sessions</p>
            </td>
          </tr>
        ) : !data?.length ? (
          <tr>
            <td colSpan={9} className="centered">
              <p>No data found</p>
            </td>
          </tr>
        ) : (
          data?.map((info, index) => (
            <tr
              key={index}
              onClick={() =>
                type === "info" && handleRowClick
                  ? handleRowClick(info)
                  : handleRowClick
                  ? handleRowClick(info?.id)
                  : handleRowClick(info?.["Device Name"])
              }
            >
              {headers?.map((header, index) =>
                header === "Service" ? (
                  <td colSpan={4} key={index}>
                    {info[header]}
                  </td>
                ) : (
                  <td key={index}>{info[header]}</td>
                )
              )}
            </tr>
          ))
        )}
      </tbody>
      {data && (
        <tfoot>
          <tr>
            <td colSpan="9">
              {data?.length > 0 && totalCount && (
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={totalCount}
                  pageSize={pageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              )}
            </td>
          </tr>
        </tfoot>
      )}
    </TableStyle>
  );
};

export default Table;
