import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "../../atoms";
import { FormStyle, FieldStyle } from "./Form.style";
import SearchDropDown from "../AdminCard/SearchDropdown";
import { getDeviceAssigned } from "../../../Redux/Zte/Device/DeviceActions";
import { useEffect, useState } from "react";
import {
  getUsersBasic,
  assignDeviceUser,
  unassignDeviceUser,
} from "../../../Redux/Zte/ZteUsers/ZteUsersActions";

const DeviceAssignmentForm = ({ setOpen }) => {
  const { deviceId } = useParams();
  const dispatch = useDispatch();
  const [isAssign, setIsAssign] = useState();
  const [unassignPreviousUser, setUnassignPreviousUser] = useState(false);
  const [userId, setUserId] = useState("");
  const [assignedUserId, setAssignedUserId] = useState("");

  const formData = ["Assign", "Unassign"];
  const assignData = {
    deviceId: deviceId,
    userID: userId,
    unassignPreviousUser: unassignPreviousUser,
  };

  const unAssignData = {
    deviceId: deviceId,
    userID: assignedUserId,
  };

  useEffect(() => {
    dispatch(getUsersBasic());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDeviceAssigned(deviceId));
  }, [deviceId, dispatch]);

  const handleChecked = () => {
    setUnassignPreviousUser((isShared) => !isShared);
  };

  const handleAssign = () => {
    dispatch(assignDeviceUser(assignData));
    setOpen(false);
  };
  const handleUnAssign = () => {
    dispatch(unassignDeviceUser(unAssignData));
    setOpen(false);
  };

  const { deviceAssigned, fetchingDeviceAssigned } = useSelector(
    (state) => state.devices
  );
  const { usersBasic, fetchingUsersBasics } = useSelector(
    (state) => state.users
  );

  return (
    <FormStyle>
      <FieldStyle>
        <SearchDropDown
          title={"Select Action"}
          type="select"
          data={formData}
          group={"formData"}
          setData={setIsAssign}
          heading={"Select Action"}
        />
        {isAssign === "Assign" && (
          <div className="button-style-top">
            <SearchDropDown
              title={"Users"}
              type="select"
              data={usersBasic}
              group={"users"}
              setData={setUserId}
              heading={"Users"}
              loading={fetchingUsersBasics}
            />
            <div className="flex button-style-top">
              <p>Unassign previous user</p>
              <div className="right">
                <input type="checkbox" onChange={handleChecked} />
              </div>
            </div>
            <div className="button-style-top">
              <Button fill="fill" onclick={handleAssign}>
                Submit
              </Button>
            </div>
          </div>
        )}

        {isAssign === "Unassign" && (
          <div className="button-style-top">
            <SearchDropDown
              title={"Users"}
              type="select"
              data={deviceAssigned}
              group={"userDevice"}
              setData={setAssignedUserId}
              heading={"Assigned Users"}
              loading={fetchingDeviceAssigned}
            />
            <div className="button-style-top">
              <Button fill="fill" onclick={handleUnAssign}>
                Submit
              </Button>
            </div>
          </div>
        )}
      </FieldStyle>
    </FormStyle>
  );
};

export default DeviceAssignmentForm;
