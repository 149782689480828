import API from "../axio";

export const searchByUsers = async (currentPage, searchquery) => {
  const searchQuery = searchquery || "";

  try {
    const { data } = await API.get(
      `user/list?PageNumber=${currentPage}&PageSize=20&Name=${searchQuery}`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};
export const getUserDetail = async (id) => {
  try {
    const { data } = await API.get(`/User/complete-info?userId=${id}`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};
export const getUserInfo = async () => {
  try {
    const { data } = await API.get(`/User/basic-info`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getUserDetails = async (userId) => {
  try {
    const { data } = await API.get(`/User/details?userId=${userId}`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getUserDeviceInfo = async (userId) => {
  try {
    const { data } = await API.get(`/User/device-info?userId=${userId}`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};
export const getUserIssueInfo = async (userId) => {
  try {
    const { data } = await API.get(`/User/issue-info?userId=${userId}`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};
export const getUserAppInfo = async (userId) => {
  try {
    const { data } = await API.get(`/User/app-info?userId=${userId}`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};
export const getUserIpInfo = async (userId) => {
  try {
    const { data } = await API.get(`/User/ip-info?userId=${userId}`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};
export const getUserLocationInfo = async (userId) => {
  try {
    const { data } = await API.get(`/User/location-info?userId=${userId}`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};


export const addUserByGroupId = async (id) => {
  try {
    const { data } = await API.post(`/User/add-by-groupId?id=${id}`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getAllUsersBasic = async () => {
  try {
    const { data } = await API.get(`/User/list-basic`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const assignDeviceToUser = async (payload) => {
  try {
    const { data } = await API.post(`/User/assign-device`, payload);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const unassignDeviceFromUser = async (payload) => {
  try {
    const { data } = await API.post(`/User/unassign-device`, payload);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getAllAzureGroupsBasic = async () => {
  try {
    const { data } = await API.get(`/User/list-azuread-groups-basic`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};
export const getAllAzureGroups = async (currentPage, searchQuery) => {
  try {
    const { data } = await API.get(`/User/list-azuread-groups?PageNumber=${currentPage}&PageSize=5&Name=${searchQuery}`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getAllRegions = async () => {
  try {
    const { data } = await API.get(`/User/list-regions`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getAllCountries = async () => {
  try {
    const { data } = await API.get(`/User/list-countries`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const initiateUsersUpdate = async (payload) => {
  try {
    const { data } = await API.post(`/User/initiate-update`, payload);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getUserDevices = async (userId) => {
  try {
    const { data } = await API.get(`/User/get-devices?userId=${userId}`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getServicesToRate = async (userId) => {
  try {
    const { data } = await API.get(
      `/User/services-to-rate?userId=${userId}`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const rateUserGenericExp = async (payload) => {
  try {
    const { data } = await API.post(`/User/rate-generic-experience`, payload);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const rateUserServiceExp = async (payload) => {
  try {
    const { data } = await API.post(`/User/rate-service-experience`, payload);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getUserRatedAllServices = async (userId) => {
  try {
    const { data } = await API.get(
      `/User/rated-all-services?userId=${userId}`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getUserRatedGeneric = async (userId) => {
  try {
    const { data } = await API.get(
      `/User/has-rated-generic?userId=${userId}`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getUserActiveConnections = async (userId) => {
  try {
    const { data } = await API.get(
      `/User/active-sessions?userId=${userId}`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};
