import { useEffect, useState } from "react";
import { AdminCardStyles } from "./AdminCard.style";
import { Table } from "../../atoms";
import { structureData } from "../../../utils/FormatData";
import { useDispatch, useSelector } from "react-redux";
import InformationModal from "../InformationModal";
import { getDeviceAssignmentSuggestions } from "../../../Redux/Zte/Device/DeviceActions";
import SortFilterMessages from "../SortFilterMessages/SortFilterMessages";

const DeviceMoveHistory = () => {
  const headers = [
    "Device Name",
    "User Principal Name",
    "Status",
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState();
  const [selectedData, setSelectedData] = useState();
  const [deviceName, setDeviceName] = useState("")
  const [userPrincipalName, setUserPrincipalName] = useState("")


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDeviceAssignmentSuggestions({currentPage, deviceName, userPrincipalName}));
    // eslint-disable-next-line
  }, [dispatch, currentPage, deviceName, userPrincipalName]);

  const { assignmentSuggestions, fetchingAssignmentSuggestions } = useSelector(
    (state) => state.devices
  );

  const handleRowClick = (id) => {
    setOpen(true);
    setSelectedData(id);
  };
  return (
    <AdminCardStyles>
      <div>
        <SortFilterMessages
          setPrincipalName={setUserPrincipalName}
          setDeviceName={setDeviceName}
          type = "deviceAssignment"
        />
      </div>
      <div className="table-style">
        <Table
          headers={headers}
          data={structureData("assignmentSuggestions", assignmentSuggestions?.data?.results)}
          loading={fetchingAssignmentSuggestions}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalCount={assignmentSuggestions?.data?.totalCount}
          pageSize={10}
          type="info"
          handleRowClick={handleRowClick}
        />
      </div>
      <InformationModal
        open={open}
        setOpen={setOpen}
        data={selectedData}
        type="deviceAssignment"
      />
    </AdminCardStyles>
  );
};

export default DeviceMoveHistory;
