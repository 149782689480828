import { useState } from "react";
import { AdminCardStyles } from "./AdminCard.style";
import { Table } from "../../atoms";
import { structureData } from "../../../utils/FormatData";
import { useSelector } from "react-redux";

const EmsGroupManagement = () => {
  const headersWG = ["WG"];
  const headersDG = ["DG"];
  const [currentPage, setCurrentPage] = useState(1);
  const { emsGroups, fetchingEmsGroups } = useSelector(
    (state) => state.devices
  );

  const emsGroupsWg =
    emsGroups?.data &&
    emsGroups?.data.filter((item) => item.value.startsWith("WG"));

  const emsGroupsDg =
    emsGroups?.data &&
    emsGroups?.data.filter((item) => item.value.startsWith("DG"));

  return (
    <AdminCardStyles>
      <div className="table-style-ems">
        <Table
          headers={headersDG}
          data={structureData("emsGroupDg", emsGroupsDg)}
          loading={fetchingEmsGroups}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalCount={emsGroups?.totalCount}
          pageSize={10}
        />
        <Table
          headers={headersWG}
          data={structureData("emsGroupWg", emsGroupsWg)}
          loading={fetchingEmsGroups}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalCount={emsGroups?.totalCount}
          pageSize={10}
        />
      </div>
    </AdminCardStyles>
  );
};

export default EmsGroupManagement;
