import { styled } from "styled-components";

export const MainStyle = styled.main`
  max-width: 1300px;
  margin: auto;
  padding: 6rem 1rem;

  .admin-headers {
    font-size: 0.875rem;
    font-weight: 700;
    color: #62646d;
    padding-left: 1rem;
  }
  .buttons-container {
    width: 100%;
    display: flex;
    justify-content: left;
  }
  .button-right {
    margin-left: auto;
  }
  .heading {
    font-size: 1rem;
    color: #62646d;
    font-weight: 700;
    margin: 12px 0;
  }
  .page-section {
    padding-top: 2rem;
    padding-left: 10.5rem;
  }

  .section-selector {
    font-size: 1.2rem;
    line-height: 1;
    font-weight: bold;
    color: #62646d;
    text-transform: uppercase;
  }
  .section-nav {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
    }
    span {
      font-size: 0.75rem;
      font-weight: 700;
      color: #62646d;
    }
    a:link,
    a:visited {
      text-decoration: none;
      font-weight: 600;
      letter-spacing: 1px;
      color: #96c115;
      text-transform: uppercase;
      font-size: 0.75rem;
      cursor: pointer;
      margin: 0;
      padding: 0;
    }
    a:hover {
      color: #62646d;
    }

    @media (max-width: 768px) {
      justify-content: center;
      overflow-x: scroll;
      margin-bottom: 0.5rem;
      a:link,
      a:visited {
        font-size: 0.5rem;
      }
      span {
        font-size: 8px;
      }
    }
  }
  @media (max-width: 768px) {
    .page-section {
      margin-top: -1rem;
    }
  }
`;

export const UserDetailCardStyle = styled.section`
  padding: 1rem 0;
  /* max-width: 800px; */
  width: 100%;
  h2 {
    font-size: 0.875rem;
    font-weight: 700;
    color: #62646d;
  }
`;

export const LayoutStyle = styled.div`
  padding: 2rem 0;
`;

export const SetOnHoldStyle = styled.div`
  display: flex;
  button {
    max-height: 40px;
  }
  .wrapper {
    width: 280px;
    margin-right: 20px;
  }

  .select-btn,
  .options li,
  .options input,
  label {
    display: flex;
    cursor: pointer;
    align-items: center;
  }

  .select-btn {
    padding: 0 20px;
    border-radius: 5px;
    justify-content: space-between;
    background: #f6f6f6;
    height: 40px;
    width: 280px;
  }

  span,
  li {
    font-size: 0.875rem;
  }
  .content {
    background: #f6f6f6;
    margin-top: 15px;
    padding: 12px;
    border-radius: 5px;
    font-size: 0.875rem;
  }

  .content .search {
    position: relative;
  }

  .search .icon {
    left: 10px;
    top: 14px;
    line-height: 53px;
    position: absolute;
    font-size: 1rem;
    color: #999;
  }

  .search input {
    height: 40px;
    width: 100%;
    font-size: 17px;
    outline: none;
    border-radius: 5px;
    padding: 0 15px 0 43px;
    border: 1px solid #96c115;
  }

  .content .options {
    margin-top: 10px;
    max-height: 250px;
    overflow-y: auto;
  }

  .options li,
  .label-input {
    height: 50px;
    padding: 0 13px;
    font-size: 16px;
    border-radius: 5px;
  }
  .options li:hover,
  .label-input:hover {
    background: #d3d3d3;
  }
  @media (max-width: 1200px) {
    display: block;
    .button-div {
      padding-top: 30px;
    }
  }
`;

export const InPageNaVWrapper = styled.div`
  position: fixed;
  top: 3.8rem;
  width: 10.4rem;
  background: #fff;
  z-index: 10;
  ul {
    margin: auto;
    padding: 0;
    list-style: none;
    display: flex;
    height: 100%;
    flex-direction: column;
    border-right: 2px solid #e2e2e2;

    li {
      color: #999;
      padding: 0.5rem 0.5rem 0.5rem 1.5rem;
      font-size: 0.8rem;
      cursor: pointer;
      &.-selected {
        color: #96c115;
      }
    }
    :hover {
      color: #62646d;
    }
    .marker {
      width: 2px;
      background: #96c115;
      position: absolute;
      transition: all 0.4s ease-in-out;
      padding: 0;
      top: 0;
      right: 0;
      height: 0px;
      pointer-events: none;
      transition: all 0.35s;
    }
    a {
      display: block;

      text-decoration: none;
    }
  }
`;
