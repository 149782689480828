import { useRef } from "react";
import GroupInformationTable from "./GroupInformationTable";
import JobsInformationTable from "./JobsInformationTable";
import SessionsTable from "./SessionsTable";
import SentMessagesTable from "./SentMessagesTable";
import InPageNavigation from "../InPageNavigation";
import ServiceGroupsTable from "./ServiceGroupsTable";
import DeviceMoveHistory from "./DeviceMoveHistory";
import DeviceAssignmentSuggestions from "./DeviceAssignmentSuggestions.js";
import EmsGroupManagement from "./EmsGroupManagement";

const InformationTab = () => {
  const sectionsRef = useRef([]);
  const sections = [
    "Group",
    "Jobs",
    "Sessions",
    "Messages",
    "AAD Group Assignments",
    "Device Movement History",
    "Device Assignment Suggestions",
    "EMS Groups",
  ];

  return (
    <>
      <div className="page-section">
        <div ref={(el) => (sectionsRef.current[0] = el)}>
          <h1 className="admin-headers" id="group">
            Group
          </h1>
          <GroupInformationTable />
        </div>
        <div ref={(el) => (sectionsRef.current[1] = el)}>
          <h1 className="admin-headers" id="jobs">
            Jobs
          </h1>
          <JobsInformationTable />
        </div>
        <div ref={(el) => (sectionsRef.current[2] = el)}>
          <h1 className="admin-headers" id="sessions">
            Sessions
          </h1>
          <SessionsTable />
        </div>
        <div ref={(el) => (sectionsRef.current[3] = el)}>
          <h1 className="admin-headers" id="messages">
            Messages
          </h1>
          <SentMessagesTable />
        </div>
        <div ref={(el) => (sectionsRef.current[4] = el)}>
          <h1 className="admin-headers" id="service-groups">
            AAD Group Assignments
          </h1>
          <ServiceGroupsTable />
        </div>
        <div ref={(el) => (sectionsRef.current[5] = el)}>
          <h1 className="admin-headers" id="service-groups">
            Device Movement History
          </h1>
          <DeviceMoveHistory />
        </div>
        <div ref={(el) => (sectionsRef.current[6] = el)}>
          <h1 className="admin-headers" id="service-groups">
            Device Assignment Suggestions
          </h1>
          <DeviceAssignmentSuggestions />
        </div>
        <div ref={(el) => (sectionsRef.current[7] = el)}>
          <h1 className="admin-headers" id="service-groups">
            EMS Groups
          </h1>
          <EmsGroupManagement />
        </div>
      </div>
      <InPageNavigation sectionsRef={sectionsRef} sections={sections} />
    </>
  );
};

export default InformationTab;
