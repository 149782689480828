import { FormStyle } from "./Form.style";
import moment from "moment";
import { Button } from "../../atoms";
import { updateSuggestionPost } from "../../../Redux/Zte/Device/DeviceActions";
import { useDispatch } from "react-redux";

const InformationForm = ({ setOpen, data, type, userDetails }) => {
  const dispatch = useDispatch();
  const handleApprove = () => {
    dispatch(
      updateSuggestionPost({
        id: data?.id,
        status: 2,
      })
    );
    setOpen(false)
  };

  const handleReject = () => {
    dispatch(
      updateSuggestionPost({
        id: data?.id,
        status: 1,
      })
    );
    setOpen(false)
  };

  return (
    <FormStyle>
      {type === "messages" ? (
        <div>
          <div className="button-style">
            <h6>Message</h6>
            <p>{data.Message}</p>
          </div>
        </div>
      ) : type === "emsMessages" ? (
        <div>
          <div className="button-style">
            <h6>EMS Message</h6>
            <p>{data.Message}</p>
          </div>
        </div>
      ) : type === "deviceAssignment" ? (
        <div>
          <div className="buttons-div button-style">
            <div className="">
              <Button fill="fill" onclick={handleApprove}>
                Approve
              </Button>
            </div>
            <div className="right">
              <Button fill="fill" onclick={handleReject}>
                Reject
              </Button>
            </div>
            <div className="right">
              <Button fill="fill" onclick={() => setOpen(false)}>
                Cancel
              </Button>
            </div>
          </div>
          <div className="button-style">
            <h6>Device Name</h6>
            <p>{data?.["Device Name"]}</p>
          </div>
          <div className="button-style">
            <h6>User Principal Name</h6>
            <p>{data?.["User Principal Name"]}</p>
          </div>
          <div className="button-style">
            <h6>Status</h6>
            <p>{data?.Status}</p>
          </div>
          <div className="button-style">
            <h6>Reason</h6>
            <p>{data?.Reason}</p>
          </div>
          <div className="button-style">
            <h6>Date Added</h6>
            <p>{data?.["Date Added"]}</p>
          </div>
          <div className="button-style">
            <h6>Date Modified</h6>
            <p>{data?.["Date Modified"]}</p>
          </div>
          <div className="button-style">
            <h6>Modified by</h6>
            <p>{data?.["Modified By"]}</p>
          </div>
        </div>
      ) : type === "app-info" ? (
        <div>
          <div className="button-style">
            <h6>Display Name</h6>
            <p>{userDetails?.basicInformation?.displayName}</p>
          </div>
          <div className="button-style">
            <h6>User Principal Name</h6>
            <p>{userDetails?.basicInformation?.userPrincipalName}</p>
          </div>
          <div className="button-style">
            <h6>App</h6>
            <p>{data?.ztnaServiceName}</p>
          </div>
          <div className="button-style">
            <h6>Date of Assignment</h6>
            <p>
              {data.dateAdded
                ? moment(data.dateAdded).format("DD-MM-YYYY")
                : "N/A"}
            </p>
          </div>
          <div className="button-style">
            <h6>First Use</h6>
            <p>
              {data.dateOfFirstUse
                ? moment(data.dateOfFirstUse).format("DD-MM-YYYY")
                : "N/A"}
            </p>
          </div>
          <div className="button-style">
            <h6>Last Use</h6>
            <p>
              {data.dateOfLastUse
                ? moment(data.dateOfLastUse).format("DD-MM-YYYY")
                : "N/A"}
            </p>
          </div>
          <div>
            <h6>Active</h6>
            <p>{data.isActive ? "True" : "False"}</p>
          </div>
        </div>
      ) : (
        <div>
          <div className="button-style">
            <h6>Description</h6>
            <p>{data.Description}</p>
          </div>
          <div>
            <h6>Modified date</h6>
            <p>{data.Modified}</p>
          </div>
        </div>
      )}
    </FormStyle>
  );
};

export default InformationForm;
