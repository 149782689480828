export const SEARCH_ZTE_USERS_FAILURE = "SEARCH_ZTE_USERS_FAILURE";
export const SEARCH_ZTE_USERS_SUCCESS = "SEARCH_ZTE_USERS_SUCCESS";
export const SEARCH_ZTE_USERS_START = "SEARCH_ZTE_USERS_START";
export const GET_USERS_DETAILS_FAILURE = "GET_USERS_DETAILS_FAILURE";
export const GET_USERS_DETAILS_SUCCESS = "GET_USERS_DETAILS_SUCCESS";
export const GET_USERS_DETAILS_START = "GET_USERS_DETAILS_START";
export const GET_LOGGED_IN_USER_ROLE_SUCCESS =
  "GET_LOGGED_IN_USER_ROLE_SUCCESS";
export const GET_LOGGED_IN_USER_SUCCESS = "GET_LOGGED_IN_USER_SUCCESS";
export const GET_LOGGED_IN_USER_FAILURE = "GET_LOGGED_IN_USER_FAILURE";
export const GET_LOGGED_IN_USER_START = "GET_LOGGED_IN_USER_START";
export const ADD_USERS_BY_GROUPID_FAILURE = "GET_USERS_BY_GROUPID_FAILURE";
export const ADD_USERS_BY_GROUPID_SUCCESS = "GET_USERS_BY_GROUPID_SUCCESS";
export const ADD_USERS_BY_GROUPID_START = "GET_USERS_BY_GROUPID_START";
export const GET_USERS_BASIC_FAILURE = "GET_USERS_BASIC_FAILURE";
export const GET_USERS_BASIC_SUCCESS = "GET_USERS_BASIC_SUCCESS";
export const GET_USERS_BASIC_START = "GET_USERS_BASIC_START";
export const ASSIGN_DEVICE_USERS_FAILURE = "ASSIGN_DEVICE_USERS_FAILURE";
export const ASSIGN_DEVICE_USERS_SUCCESS = "ASSIGN_DEVICE_USERS_SUCCESS";
export const ASSIGN_DEVICE_USERS_START = "ASSIGN_DEVICE_USERS_START";
export const UNASSIGN_DEVICE_USERS_FAILURE = "UNASSIGN_DEVICE_USERS_FAILURE";
export const UNASSIGN_DEVICE_USERS_SUCCESS = "UNASSIGN_DEVICE_USERS_SUCCESS";
export const UNASSIGN_DEVICE_USERS_START = "UNASSIGN_DEVICE_USERS_START";
export const GET_AZURE_GROUP_FAILURE = "GET_AZURE_GROUP_FAILURE";
export const GET_AZURE_GROUP_SUCCESS = "GET_AZURE_GROUP_SUCCESS";
export const GET_AZURE_GROUP_START = "GET_AZURE_GROUP_START";
export const GET_AZURE_GROUP_BASIC_FAILURE = "GET_AZURE_GROUP_BASIC_FAILURE";
export const GET_AZURE_GROUP_BASIC_SUCCESS = "GET_AZURE_GROUP_BASIC_SUCCESS";
export const GET_AZURE_GROUP_BASIC_START = "GET_AZURE_GROUP_BASIC_START";
export const INITIATE_USERS_UPDATE_FAILURE = "INITIATE_USERS_UPDATE_FAILURE";
export const INITIATE_USERS_UPDATE_SUCCESS = "INITIATE_USERS_UPDATE_SUCCESS";
export const INITIATE_USERS_UPDATE_START = "INITIATE_USERS_UPDATE_START";
export const GET_USERS_REGIONS_FAILURE = "GET_USERS_REGIONS_FAILURE";
export const GET_USERS_REGIONS_SUCCESS = "GET_USERS_REGIONS_SUCCESS";
export const GET_USERS_REGIONS_START = "GET_USERS_REGIONS_START";
export const GET_USERS_COUNTRIES_FAILURE = "GET_USERS_COUNTRIES_FAILURE";
export const GET_USERS_COUNTRIES_SUCCESS = "GET_USERS_COUNTRIES_SUCCESS";
export const GET_USERS_COUNTRIES_START = "GET_USERS_COUNTRIES_START";
export const GET_USER_DEVICES_FAILURE = "GET_USER_DEVICES_FAILURE";
export const GET_USER_DEVICES_SUCCESS = "GET_USER_DEVICES_SUCCESS";
export const GET_USER_DEVICES_START = "GET_USER_DEVICES_START";
export const GET_RATE_SERVICES_START = "GET_RATE_SERVICES_START";
export const GET_RATE_SERVICES_FAILURE = "GET_RATE_SERVICES_FAILURE";
export const GET_RATE_SERVICES_SUCCESS = "GET_RATE_SERVICES_SUCCESS";
export const RATE_USER_GENERIC_START = "GET_RATE_USER_GENERIC_START";
export const RATE_USER_GENERIC_FAILURE = "GET_RATE_USER_GENERIC_FAILURE";
export const RATE_USER_GENERIC_SUCCESS = "GET_RATE_USER_GENERIC_SUCCESS";
export const RATE_USER_SERVICES_START = "GET_RATE_USER_SERVICES_START";
export const RATE_USER_SERVICES_FAILURE = "GET_RATE_USER_SERVICES_FAILURE";
export const RATE_USER_SERVICES_SUCCESS = "GET_RATE_USER_SERVICES_SUCCESS";
export const GET_USER_RATED_ALL_SERVICES_START = "GET_USER_RATED_ALL_SERVICES_START";
export const GET_USER_RATED_ALL_SERVICES_FAILURE = "GET_USER_RATED_ALL_SERVICES_FAILURE";
export const GET_USER_RATED_ALL_SERVICES_SUCCESS = "GET_USER_RATED_ALL_SERVICES_SUCCESS";
export const GET_USER_RATED_GENERIC_START = "GET_USER_RATED_GENERIC_START";
export const GET_USER_RATED_GENERIC_FAILURE = "GET_USER_RATED_GENERIC_FAILURE";
export const GET_USER_RATED_GENERIC_SUCCESS = "GET_USER_RATED_GENERIC_SUCCESS";
export const GET_USER_ACTIVE_CONNECTIONS_START = "GET_USER_ACTIVE_CONNECTIONS_START";
export const GET_USER_ACTIVE_CONNECTIONS_FAILURE = "GET_USER_ACTIVE_CONNECTIONS_FAILURE";
export const GET_USER_ACTIVE_CONNECTIONS_SUCCESS = "GET_USER_ACTIVE_CONNECTIONS_SUCCESS";
export const GET_USERS_DEVICE_INFO_FAILURE = "GET_USERS_DEVICE_INFO_FAILURE";
export const GET_USERS_DEVICE_INFO_SUCCESS = "GET_USERS_DEVICE_INFO_SUCCESS";
export const GET_USERS_DEVICE_INFO_START = "GET_USERS_DEVICE_INFO_START";
export const GET_USERS_ISSUE_INFO_FAILURE = "GET_USERS_ISSUE_INFO_FAILURE";
export const GET_USERS_ISSUE_INFO_SUCCESS = "GET_USERS_ISSUE_INFO_SUCCESS";
export const GET_USERS_ISSUE_INFO_START = "GET_USERS_ISSUE_INFO_START";
export const GET_USERS_APP_INFO_FAILURE = "GET_USERS_APP_INFO_FAILURE";
export const GET_USERS_APP_INFO_SUCCESS = "GET_USERS_APP_INFO_SUCCESS";
export const GET_USERS_APP_INFO_START = "GET_USERS_APP_INFO_START";
export const GET_USERS_IP_INFO_FAILURE = "GET_USERS_IP_INFO_FAILURE";
export const GET_USERS_IP_INFO_SUCCESS = "GET_USERS_IP_INFO_SUCCESS";
export const GET_USERS_IP_INFO_START = "GET_USERS_IP_INFO_START";
export const GET_USERS_LOCATION_INFO_FAILURE = "GET_LOCATION_INFO_FAILURE";
export const GET_USERS_LOCATION_INFO_SUCCESS = "GET_LOCATION_INFO_SUCCESS";
export const GET_USERS_LOCATION_INFO_START = "GET_LOCATION_INFO_START";