import {
  searchByDevices,
  getDeviceDetail,
  getAllDevicesBasic,
  addDevice,
  initiateDeviceUpdate,
  uploadDevices,
  deviceSendMessage,
  getSentMessages,
  getDeviceMovement,
  moveDevices,
  getAssignmentSuggestions,
  getListEmsGroups,
  addEmsGroups,
  getDeviceAssignedUsers,
  updateSharedStatus,
  updateSuggestion,
} from "../../../api/zte/device";
import {
  GET_DEVICE_FAILURE,
  GET_DEVICE_SUCCESS,
  GET_DEVICE_START,
  GET_DEVICE_DETAIL_FAILURE,
  GET_DEVICE_DETAIL_START,
  GET_DEVICE_DETAIL_SUCCESS,
  GET_DEVICE_BASIC_FAILURE,
  GET_DEVICE_BASIC_SUCCESS,
  GET_DEVICE_BASIC_START,
  ADD_DEVICE_FAILURE,
  ADD_DEVICE_SUCCESS,
  ADD_DEVICE_START,
  ADD_DEVICE_UPDATE_START,
  ADD_DEVICE_UPDATE_FAILURE,
  ADD_DEVICE_UPDATE_SUCCESS,
  UPLOAD_DEVICES_FAILURE,
  UPLOAD_DEVICES_SUCCESS,
  UPLOAD_DEVICES_START,
  DEVICE_SEND_MESSAGE_FAILURE,
  DEVICE_SEND_MESSAGE_SUCCESS,
  DEVICE_SEND_MESSAGE_START,
  GET_SENT_MESSAGES_FAILURE,
  GET_SENT_MESSAGES_SUCCESS,
  GET_SENT_MESSAGES_START,
  GET_DEVICE_MOVEMENT_FAILURE,
  GET_DEVICE_MOVEMENT_SUCCESS,
  GET_DEVICE_MOVEMENT_START,
  DEVICE_MOVE_FAILURE,
  DEVICE_MOVE_SUCCESS,
  DEVICE_MOVE_START,
  DEVICE_ASSIGNMENT_SUGGESTIONS_FAILURE,
  DEVICE_ASSIGNMENT_SUGGESTIONS_SUCCESS,
  DEVICE_ASSIGNMENT_SUGGESTIONS_START,
  GET_LIST_EMS_GROUP_FAILURE,
  GET_LIST_EMS_GROUP_SUCCESS,
  GET_LIST_EMS_GROUP_START,
  ADD_EMS_GROUP_FAILURE,
  ADD_EMS_GROUP_SUCCESS,
  ADD_EMS_GROUP_START,
  GET_DEVICE_ASSIGNED_FAILURE,
  GET_DEVICE_ASSIGNED_SUCCESS,
  GET_DEVICE_ASSIGNED_START,
  UPDATE_SHARED_STATUS_FAILURE,
  UPDATE_SHARED_STATUS_SUCCESS,
  UPDATE_SHARED_STATUS_START,
  UPDATE_SUGGESTION_FAILURE,
  UPDATE_SUGGESTION_SUCCESS,
  UPDATE_SUGGESTION_START,
} from "./DeviceActionTypes";
import { toast } from "react-toastify";

export const searchDeviceStart = () => {
  return {
    type: GET_DEVICE_START,
  };
};
export const searchDeviceFailed = (payload) => {
  return {
    type: GET_DEVICE_FAILURE,
    payload,
  };
};
export const searchDeviceSuccess = (payload) => {
  return {
    type: GET_DEVICE_SUCCESS,
    payload,
  };
};

export const searchDashboardByDevice =
  (currentPage, searchQuery) => async (dispatch) => {
    dispatch(searchDeviceStart());
    const { data, error } = await searchByDevices(currentPage, searchQuery);
    if (data && data?.statusCode === "90000") {
      dispatch(searchDeviceSuccess(data?.data));
    } else if (data?.statusCode === "10001") {
      toast.error(data?.message);
    } else {
      dispatch(searchDeviceFailed(error.response.data.error));
      toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
    }
  };

export const getDeviceDetailStart = () => {
  return {
    type: GET_DEVICE_DETAIL_START,
  };
};
export const getDeviceDetailFailed = (payload) => {
  return {
    type: GET_DEVICE_DETAIL_FAILURE,
    payload,
  };
};
export const getDeviceDetailSuccess = (payload) => {
  return {
    type: GET_DEVICE_DETAIL_SUCCESS,
    payload,
  };
};

export const getDeviceDetailById = (id) => async (dispatch) => {
  dispatch(getDeviceDetailStart());
  const { data, error } = await getDeviceDetail(id);
  if (data && data?.statusCode === "90000") {
    dispatch(getDeviceDetailSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(getDeviceDetailFailed(error.response.data.error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getDevicesBasicStart = () => {
  return {
    type: GET_DEVICE_BASIC_START,
  };
};
export const getDevicesBasicFailed = (payload) => {
  return {
    type: GET_DEVICE_BASIC_FAILURE,
    payload,
  };
};
export const getDevicesBasicSuccess = (payload) => {
  return {
    type: GET_DEVICE_BASIC_SUCCESS,
    payload,
  };
};

export const getDevicesBasic = () => async (dispatch) => {
  dispatch(getDevicesBasicStart());
  const { data, error } = await getAllDevicesBasic();
  if (data && data?.statusCode === "90000") {
    dispatch(getDevicesBasicSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(getDevicesBasicFailed(error.response.data.error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const addDeviceStart = () => {
  return {
    type: ADD_DEVICE_START,
  };
};

export const addDeviceSuccess = (payload) => {
  return {
    type: ADD_DEVICE_SUCCESS,
    payload,
  };
};

export const addDeviceFailed = (payload) => {
  return {
    type: ADD_DEVICE_FAILURE,
    payload,
  };
};

export const addDevicePost = (payload) => async (dispatch) => {
  dispatch(addDeviceStart());
  const { data, error } = await addDevice(payload);
  if (data && data?.statusCode === "90000") {
    dispatch(addDeviceSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(addDeviceFailed(error.response.data.error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const initiateDeviceUpdateStart = () => {
  return {
    type: ADD_DEVICE_UPDATE_START,
  };
};

export const initiateDeviceUpdateSuccess = (payload) => {
  return {
    type: ADD_DEVICE_UPDATE_SUCCESS,
    payload,
  };
};

export const initiateDeviceUpdateFailed = (payload) => {
  return {
    type: ADD_DEVICE_UPDATE_FAILURE,
    payload,
  };
};

export const initiateDeviceUpdatePost = (payload) => async (dispatch) => {
  dispatch(initiateDeviceUpdateStart());
  const { data, error } = await initiateDeviceUpdate(payload);
  if (data && data?.statusCode === "90000") {
    dispatch(initiateDeviceUpdateSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(initiateDeviceUpdateFailed(error.response.data.error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const uploadDevicesStart = () => {
  return {
    type: UPLOAD_DEVICES_START,
  };
};

export const uploadDevicesSuccess = (payload) => {
  return {
    type: UPLOAD_DEVICES_SUCCESS,
    payload,
  };
};

export const uploadDevicesFailed = (payload) => {
  return {
    type: UPLOAD_DEVICES_FAILURE,
    payload,
  };
};

export const uploadDevicesPost = (file) => async (dispatch) => {
  dispatch(uploadDevicesStart());
  const { data, error } = await uploadDevices(file);
  if (data && data?.statusCode === "90000") {
    dispatch(uploadDevicesSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(uploadDevicesFailed(error.response.data.error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const deviceSendMessageStart = () => {
  return {
    type: DEVICE_SEND_MESSAGE_START,
  };
};

export const deviceSendMessageSuccess = (payload) => {
  return {
    type: DEVICE_SEND_MESSAGE_SUCCESS,
    payload,
  };
};

export const deviceSendMessageFailed = (payload) => {
  return {
    type: DEVICE_SEND_MESSAGE_FAILURE,
    payload,
  };
};

export const deviceSendMessagePost = (payload) => async (dispatch) => {
  dispatch(deviceSendMessageStart());
  const { data, error } = await deviceSendMessage(payload);
  if (data && data?.statusCode === "90000") {
    dispatch(deviceSendMessageSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(deviceSendMessageFailed(error.response.data.error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getSentMessagesStart = () => {
  return {
    type: GET_SENT_MESSAGES_START,
  };
};
export const getSentMessagesFailed = (payload) => {
  return {
    type: GET_SENT_MESSAGES_FAILURE,
    payload,
  };
};
export const getSentMessagesSuccess = (payload) => {
  return {
    type: GET_SENT_MESSAGES_SUCCESS,
    payload,
  };
};

export const getDeviceSentMessages =
  (
    currentPage,
    startDate,
    endDate,
    sortOrder,
    sortParams,
    message,
    username,
    deviceName
  ) =>
  async (dispatch) => {
    dispatch(getSentMessagesStart());
    const { data, error } = await getSentMessages(
      currentPage,
      startDate,
      endDate,
      sortOrder,
      sortParams,
      message,
      username,
      deviceName
    );
    if (data && data?.statusCode === "90000") {
      dispatch(getSentMessagesSuccess(data?.data));
    } else if (data?.statusCode === "10001") {
      toast.error(data?.message);
    } else {
      dispatch(getSentMessagesFailed(error.response.data.error));
      toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
    }
  };

export const getDeviceMovementStart = () => {
  return {
    type: GET_DEVICE_MOVEMENT_START,
  };
};
export const getDeviceMovementFailed = (payload) => {
  return {
    type: GET_DEVICE_MOVEMENT_FAILURE,
    payload,
  };
};
export const getDeviceMovementSuccess = (payload) => {
  return {
    type: GET_DEVICE_MOVEMENT_SUCCESS,
    payload,
  };
};

export const getDeviceMovementHistory =
  (
    currentPage,
    startDate,
    endDate,
    sortOrder,
    sortParams,
    host,
    message,
    deviceName
  ) =>
  async (dispatch) => {
    dispatch(getDeviceMovementStart());
    const { data, error } = await getDeviceMovement(
      currentPage,
      startDate,
      endDate,
      sortOrder,
      sortParams,
      host,
      message,
      deviceName
    );
    if (data && data?.statusCode === "90000") {
      dispatch(getDeviceMovementSuccess(data?.data));
    } else if (data?.statusCode === "10001") {
      toast.error(data?.message);
    } else {
      dispatch(getDeviceMovementFailed(error.response.data.error));
      toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
    }
  };

export const moveDevicesStart = () => {
  return {
    type: DEVICE_MOVE_START,
  };
};

export const moveDevicesSuccess = (payload) => {
  return {
    type: DEVICE_MOVE_SUCCESS,
    payload,
  };
};

export const moveDevicesFailed = (payload) => {
  return {
    type: DEVICE_MOVE_FAILURE,
    payload,
  };
};

export const moveDevicesPost = (payload) => async (dispatch) => {
  dispatch(moveDevicesStart());
  const { data, error } = await moveDevices(payload);
  if (data && data?.statusCode === "90000") {
    dispatch(moveDevicesSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(moveDevicesFailed(error.response.data.error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getAssignmentSuggestionsStart = () => {
  return {
    type: DEVICE_ASSIGNMENT_SUGGESTIONS_START,
  };
};
export const getAssignmentSuggestionsFailed = (payload) => {
  return {
    type: DEVICE_ASSIGNMENT_SUGGESTIONS_FAILURE,
    payload,
  };
};
export const getAssignmentSuggestionsSuccess = (payload) => {
  return {
    type: DEVICE_ASSIGNMENT_SUGGESTIONS_SUCCESS,
    payload,
  };
};

export const getDeviceAssignmentSuggestions =
  ({ currentPage, deviceName, userPrincipalName }) =>
  async (dispatch) => {
    dispatch(getAssignmentSuggestionsStart());
    const { data, error } = await getAssignmentSuggestions({
      currentPage,
      deviceName,
      userPrincipalName,
    });
    if (data && data?.statusCode === "90000") {
      dispatch(getAssignmentSuggestionsSuccess(data));
    } else if (data?.statusCode === "10001") {
      toast.error(data?.message);
    } else {
      dispatch(getAssignmentSuggestionsFailed(error.response.data.error));
      toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
    }
  };

export const getListEmsgroupsStart = () => {
  return {
    type: GET_LIST_EMS_GROUP_START,
  };
};
export const getListEmsgroupsFailed = (payload) => {
  return {
    type: GET_LIST_EMS_GROUP_FAILURE,
    payload,
  };
};
export const getListEmsgroupsSuccess = (payload) => {
  return {
    type: GET_LIST_EMS_GROUP_SUCCESS,
    payload,
  };
};

export const getListofEmsgroups = () => async (dispatch) => {
  dispatch(getListEmsgroupsStart());
  const { data, error } = await getListEmsGroups();
  if (data && data?.statusCode === "90000") {
    dispatch(getListEmsgroupsSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(getListEmsgroupsFailed(error.response.data.error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const addEmsGroupStart = () => {
  return {
    type: ADD_EMS_GROUP_START,
  };
};

export const addEmsGroupSuccess = (payload) => {
  return {
    type: ADD_EMS_GROUP_SUCCESS,
    payload,
  };
};

export const addEmsGroupFailed = (payload) => {
  return {
    type: ADD_EMS_GROUP_FAILURE,
    payload,
  };
};

export const addEmsGroupPost = (payload) => async (dispatch) => {
  dispatch(addEmsGroupStart());
  const { data, error } = await addEmsGroups(payload);
  if (data && data?.statusCode === "90000") {
    dispatch(addEmsGroupSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(addEmsGroupFailed(error.response.data.error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getDeviceAssignedStart = () => {
  return {
    type: GET_DEVICE_ASSIGNED_START,
  };
};
export const getDeviceAssignedFailed = (payload) => {
  return {
    type: GET_DEVICE_ASSIGNED_FAILURE,
    payload,
  };
};
export const getDeviceAssignedSuccess = (payload) => {
  return {
    type: GET_DEVICE_ASSIGNED_SUCCESS,
    payload,
  };
};

export const getDeviceAssigned = (id) => async (dispatch) => {
  dispatch(getDeviceAssignedStart());
  const { data, error } = await getDeviceAssignedUsers(id);
  if (data && data?.statusCode === "90000") {
    dispatch(getDeviceAssignedSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(getDeviceAssignedFailed(error.response.data.error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const updateSharedStatusStart = () => {
  return {
    type: UPDATE_SHARED_STATUS_START,
  };
};

export const updateSharedStatusSuccess = (payload) => {
  return {
    type: UPDATE_SHARED_STATUS_SUCCESS,
    payload,
  };
};

export const updateSharedStatusFailed = (payload) => {
  return {
    type: UPDATE_SHARED_STATUS_FAILURE,
    payload,
  };
};

export const updateSharedStatusPatch = (payload) => async (dispatch) => {
  dispatch(updateSharedStatusStart());
  const { data, error } = await updateSharedStatus(payload);
  if (data && data?.statusCode === "90000") {
    dispatch(updateSharedStatusSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(updateSharedStatusFailed(error.response.data.error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const updateSuggestionStart = () => {
  return {
    type: UPDATE_SUGGESTION_START,
  };
};

export const updateSuggestionSuccess = (payload) => {
  return {
    type: UPDATE_SUGGESTION_SUCCESS,
    payload,
  };
};

export const updateSuggestionFailed = (payload) => {
  return {
    type: UPDATE_SUGGESTION_FAILURE,
    payload,
  };
};

export const updateSuggestionPost = (payload) => async (dispatch) => {
  dispatch(updateSuggestionStart());
  const { data, error } = await updateSuggestion(payload);
  if (data && data?.statusCode === "90000") {
    dispatch(updateSuggestionSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(updateSuggestionFailed(error.response.data.error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};
