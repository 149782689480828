import {
  SEARCH_ZTE_USERS_FAILURE,
  SEARCH_ZTE_USERS_START,
  SEARCH_ZTE_USERS_SUCCESS,
  GET_USERS_DETAILS_FAILURE,
  GET_USERS_DETAILS_START,
  GET_USERS_DETAILS_SUCCESS,
  GET_LOGGED_IN_USER_ROLE_SUCCESS,
  GET_LOGGED_IN_USER_SUCCESS,
  GET_LOGGED_IN_USER_FAILURE,
  ADD_USERS_BY_GROUPID_FAILURE,
  ADD_USERS_BY_GROUPID_SUCCESS,
  ADD_USERS_BY_GROUPID_START,
  GET_USERS_BASIC_FAILURE,
  GET_USERS_BASIC_SUCCESS,
  GET_USERS_BASIC_START,
  ASSIGN_DEVICE_USERS_FAILURE,
  ASSIGN_DEVICE_USERS_SUCCESS,
  ASSIGN_DEVICE_USERS_START,
  UNASSIGN_DEVICE_USERS_FAILURE,
  UNASSIGN_DEVICE_USERS_SUCCESS,
  UNASSIGN_DEVICE_USERS_START,
  GET_AZURE_GROUP_FAILURE,
  GET_AZURE_GROUP_SUCCESS,
  GET_AZURE_GROUP_START,
  GET_AZURE_GROUP_BASIC_FAILURE,
  GET_AZURE_GROUP_BASIC_SUCCESS,
  GET_AZURE_GROUP_BASIC_START,
  INITIATE_USERS_UPDATE_FAILURE,
  INITIATE_USERS_UPDATE_SUCCESS,
  INITIATE_USERS_UPDATE_START,
  GET_USERS_REGIONS_FAILURE,
  GET_USERS_REGIONS_SUCCESS,
  GET_USERS_REGIONS_START,
  GET_USERS_COUNTRIES_FAILURE,
  GET_USERS_COUNTRIES_SUCCESS,
  GET_USERS_COUNTRIES_START,
  GET_LOGGED_IN_USER_START,
  GET_USER_DEVICES_FAILURE,
  GET_USER_DEVICES_SUCCESS,
  GET_USER_DEVICES_START,
  GET_RATE_SERVICES_START,
  GET_RATE_SERVICES_FAILURE,
  GET_RATE_SERVICES_SUCCESS,
  RATE_USER_GENERIC_START,
  RATE_USER_GENERIC_FAILURE,
  RATE_USER_GENERIC_SUCCESS,
  RATE_USER_SERVICES_START,
  RATE_USER_SERVICES_FAILURE,
  RATE_USER_SERVICES_SUCCESS,
  GET_USER_RATED_ALL_SERVICES_START,
  GET_USER_RATED_ALL_SERVICES_FAILURE,
  GET_USER_RATED_ALL_SERVICES_SUCCESS,
  GET_USER_RATED_GENERIC_START,
  GET_USER_RATED_GENERIC_FAILURE,
  GET_USER_RATED_GENERIC_SUCCESS,
  GET_USER_ACTIVE_CONNECTIONS_START,
  GET_USER_ACTIVE_CONNECTIONS_FAILURE,
  GET_USER_ACTIVE_CONNECTIONS_SUCCESS,
  GET_USERS_DEVICE_INFO_FAILURE,
  GET_USERS_DEVICE_INFO_SUCCESS,
  GET_USERS_DEVICE_INFO_START,
  GET_USERS_ISSUE_INFO_FAILURE,
  GET_USERS_ISSUE_INFO_SUCCESS,
  GET_USERS_ISSUE_INFO_START,
  GET_USERS_APP_INFO_FAILURE,
  GET_USERS_APP_INFO_SUCCESS,
  GET_USERS_APP_INFO_START,
  GET_USERS_IP_INFO_FAILURE,
  GET_USERS_IP_INFO_SUCCESS,
  GET_USERS_IP_INFO_START,
  GET_USERS_LOCATION_INFO_FAILURE,
  GET_USERS_LOCATION_INFO_SUCCESS,
  GET_USERS_LOCATION_INFO_START
} from "./ZteUsersActionTypes";
import {
  searchByUsers,
  getUserDetails,
  getUserInfo,
  getUserDeviceInfo,
  getUserIssueInfo,
  getUserAppInfo,
  getUserIpInfo,
  getUserLocationInfo,
  addUserByGroupId,
  getAllUsersBasic,
  assignDeviceToUser,
  unassignDeviceFromUser,
  getAllAzureGroupsBasic,
  getAllAzureGroups,
  initiateUsersUpdate,
  getAllRegions,
  getAllCountries,
  getUserDevices,
  getServicesToRate,
  rateUserGenericExp,
  rateUserServiceExp,
  getUserRatedAllServices,
  getUserRatedGeneric,
  getUserActiveConnections,
} from "../../../api/zte/users";
import { toast } from "react-toastify";

export const searchUsersStart = () => {
  return {
    type: SEARCH_ZTE_USERS_START,
  };
};
export const searchUsersFailed = (payload) => {
  return {
    type: SEARCH_ZTE_USERS_FAILURE,
    payload,
  };
};
export const getUsersDetailsSuccess = (payload) => {
  return {
    type: GET_USERS_DETAILS_SUCCESS,
    payload,
  };
};

export const getUsersDetailsStart = () => {
  return {
    type: GET_USERS_DETAILS_START,
  };
};
export const getUsersDetailsFailed = (payload) => {
  return {
    type: GET_USERS_DETAILS_FAILURE,
    payload,
  };
};
export const getUsersBasicStart = () => {
  return {
    type: GET_USERS_BASIC_START,
  };
};

export const getUsersBasicSuccess = (payload) => {
  return {
    type: GET_USERS_BASIC_SUCCESS,
    payload,
  };
};

export const getUsersBasicFailed = (payload) => {
  return {
    type: GET_USERS_BASIC_FAILURE,
    payload,
  };
};
export const searchUsersSuccess = (payload) => {
  return {
    type: SEARCH_ZTE_USERS_SUCCESS,
    payload,
  };
};
export const assignDeviceUsersStart = () => {
  return {
    type: ASSIGN_DEVICE_USERS_START,
  };
};

export const assignDeviceUsersSuccess = (payload) => {
  return {
    type: ASSIGN_DEVICE_USERS_SUCCESS,
    payload,
  };
};

export const assignDeviceUsersFailed = (payload) => {
  return {
    type: ASSIGN_DEVICE_USERS_FAILURE,
    payload,
  };
};
export const unassignDeviceUsersStart = () => {
  return {
    type: UNASSIGN_DEVICE_USERS_START,
  };
};

export const unassignDeviceUsersSuccess = (payload) => {
  return {
    type: UNASSIGN_DEVICE_USERS_SUCCESS,
    payload,
  };
};

export const unassignDeviceUsersFailed = (payload) => {
  return {
    type: UNASSIGN_DEVICE_USERS_FAILURE,
    payload,
  };
};
export const getAzureGroupStart = () => {
  return {
    type: GET_AZURE_GROUP_START,
  };
};

export const getAzureGroupSuccess = (payload) => {
  return {
    type: GET_AZURE_GROUP_SUCCESS,
    payload,
  };
};

export const getAzureGroupFailed = (payload) => {
  return {
    type: GET_AZURE_GROUP_FAILURE,
    payload,
  };
};
export const getAzureGroupBasicStart = () => {
  return {
    type: GET_AZURE_GROUP_BASIC_START,
  };
};

export const getAzureGroupBasicSuccess = (payload) => {
  return {
    type: GET_AZURE_GROUP_BASIC_SUCCESS,
    payload,
  };
};

export const getAzureGroupBasicFailed = (payload) => {
  return {
    type: GET_AZURE_GROUP_BASIC_FAILURE,
    payload,
  };
};
export const initiateUsersUpdateStart = () => {
  return {
    type: INITIATE_USERS_UPDATE_START,
  };
};

export const initiateUsersUpdateSuccess = (payload) => {
  return {
    type: INITIATE_USERS_UPDATE_SUCCESS,
    payload,
  };
};

export const initiateUsersUpdateFailed = (payload) => {
  return {
    type: INITIATE_USERS_UPDATE_FAILURE,
    payload,
  };
};
export const addUserByGroupIdStart = () => {
  return {
    type: ADD_USERS_BY_GROUPID_START,
  };
};

export const addUserByGroupIdSuccess = (payload) => {
  return {
    type: ADD_USERS_BY_GROUPID_SUCCESS,
    payload,
  };
};

export const addUserByGroupIdFailed = (payload) => {
  return {
    type: ADD_USERS_BY_GROUPID_FAILURE,
    payload,
  };
};

export const getUsersRegionsStart = () => {
  return {
    type: GET_USERS_REGIONS_START,
  };
};

export const getUsersRegionsSuccess = (payload) => {
  return {
    type: GET_USERS_REGIONS_SUCCESS,
    payload,
  };
};

export const getUsersRegionsFailed = (payload) => {
  return {
    type: GET_USERS_REGIONS_FAILURE,
    payload,
  };
};

export const getUsersCountriesStart = () => {
  return {
    type: GET_USERS_COUNTRIES_START,
  };
};

export const getUsersCountriesSuccess = (payload) => {
  return {
    type: GET_USERS_COUNTRIES_SUCCESS,
    payload,
  };
};

export const getUsersCountriesFailed = (payload) => {
  return {
    type: GET_USERS_COUNTRIES_FAILURE,
    payload,
  };
};

export const getUserDevicesStart = () => {
  return {
    type: GET_USER_DEVICES_START,
  };
};

export const getUserDevicesSuccess = (payload) => {
  return {
    type: GET_USER_DEVICES_SUCCESS,
    payload,
  };
};

export const getUserDevicesFailed = (payload) => {
  return {
    type: GET_USER_DEVICES_FAILURE,
    payload,
  };
};

export const searchDashboardByUser =
  (currentPage, searchQuery) => async (dispatch) => {
    dispatch(searchUsersStart());
    const { data, error } = await searchByUsers(currentPage, searchQuery);
    if (data && data?.statusCode === "90000") {
      dispatch(searchUsersSuccess(data?.data));
    } else if (data?.statusCode === "10001") {
      toast.error(data.message);
    } else {
      dispatch(searchUsersFailed(error));
      toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
    }
  };
    
  export const getUserDetailsById =
  (id = "00716bbc-b9a6-417a-a66c-bbdc0db233a1") =>
  async (dispatch) => {
    dispatch(getUsersDetailsStart());
    const { data, error } = await getUserDetails(id);
    if (data && data?.statusCode === "90000") {
      dispatch(getUsersDetailsSuccess(data));
    } else if (data?.statusCode === "10001") {
      toast.error(data.message);
    } else {
      dispatch(getUsersDetailsFailed(error));
      toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
    }
  };

export const getUsersBasic = () => async (dispatch) => {
  dispatch(getUsersBasicStart());
  const { data, error } = await getAllUsersBasic();
  if (data && data?.statusCode === "90000") {
    dispatch(getUsersBasicSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(getUsersBasicFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const assignDeviceUser = (payload) => async (dispatch) => {
  dispatch(assignDeviceUsersStart());
  const { data, error } = await assignDeviceToUser(payload);
  if (data && data?.statusCode === "90000") {
    dispatch(assignDeviceUsersSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(assignDeviceUsersFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const unassignDeviceUser = (payload) => async (dispatch) => {
  dispatch(unassignDeviceUsersStart());
  const { data, error } = await unassignDeviceFromUser(payload);
  if (data && data?.statusCode === "90000") {
    dispatch(unassignDeviceUsersSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(unassignDeviceUsersFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getAzureGroupsBasic = () => async (dispatch) => {
  dispatch(getAzureGroupBasicStart());
  const { data, error } = await getAllAzureGroupsBasic();
  if (data && data?.statusCode === "90000") {
    dispatch(getAzureGroupBasicSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(getAzureGroupBasicFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

const getLoggedInUserRoleSuccess = (payload) => {
  return {
    type: GET_LOGGED_IN_USER_ROLE_SUCCESS,
    payload,
  };
};
const getLoggedInUserSuccess = (payload) => {
  return {
    type: GET_LOGGED_IN_USER_SUCCESS,
    payload,
  };
};
const getLoggedInUserFailed = (payload) => {
  return {
    type: GET_LOGGED_IN_USER_FAILURE,
    payload,
  };
};
const getLoggedInUserStart = () => {
  return {
    type: GET_LOGGED_IN_USER_START,
  };
};

export const getLoggedInUserRole = (role) => async (dispatch) => {
  dispatch(getLoggedInUserRoleSuccess(role));
  dispatch(getLoggedInUserStart());
  const { data, error } = await getUserInfo();
  if (data && data?.statusCode === "90000") {
    dispatch(getLoggedInUserSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(getLoggedInUserFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};
export const getAzureGroups =
  (currentPage, searchQuery) => async (dispatch) => {
    dispatch(getAzureGroupStart());
    const { data, error } = await getAllAzureGroups(currentPage, searchQuery);
    if (data && data?.statusCode === "90000") {
      dispatch(getAzureGroupSuccess(data));
    } else if (data?.statusCode === "10001") {
      toast.error(data?.message);
    } else {
      dispatch(getAzureGroupFailed(error));
      toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
    }
  };

export const initiateUsersUpdateGet = (payload) => async (dispatch) => {
  dispatch(initiateUsersUpdateStart());
  const { data, error } = await initiateUsersUpdate(payload);
  if (data && data?.statusCode === "90000") {
    dispatch(initiateUsersUpdateSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(initiateUsersUpdateFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const addUserByGroup = (id) => async (dispatch) => {
  dispatch(addUserByGroupIdStart());
  const { data, error } = await addUserByGroupId(id);
  if (data && data?.statusCode === "90000") {
    dispatch(addUserByGroupIdSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    toast.error(error?.title);
    dispatch(addUserByGroupIdFailed(error));
  }
};

export const getUsersRegions = () => async (dispatch) => {
  dispatch(getUsersRegionsStart());
  const { data, error } = await getAllRegions();
  if (data && data?.statusCode === "90000") {
    dispatch(getUsersRegionsSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(getUsersRegionsFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getUsersCountries = () => async (dispatch) => {
  dispatch(getUsersCountriesStart());
  const { data, error } = await getAllCountries();
  if (data && data?.statusCode === "90000") {
    dispatch(getUsersCountriesSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(getUsersCountriesFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getUsersDevicesById = (id) => async (dispatch) => {
  dispatch(getUserDevicesStart());
  const { data, error } = await getUserDevices(id);
  if (data && data?.statusCode === "90000") {
    dispatch(getUserDevicesSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(getUserDevicesFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getRateServicesStart = () => {
  return {
    type: GET_RATE_SERVICES_START,
  };
};

export const getRateServicesSuccess = (payload) => {
  return {
    type: GET_RATE_SERVICES_SUCCESS,
    payload,
  };
};

export const getRateServicesFailed = (payload) => {
  return {
    type: GET_RATE_SERVICES_FAILURE,
    payload,
  };
};

export const fetchGetRateServices = (userId) => async (dispatch) => {
  dispatch(getRateServicesStart());
  const { data, error } = await getServicesToRate(userId);
  if (data && data?.statusCode === "90000") {
    dispatch(getRateServicesSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(getRateServicesFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const rateUserGenericExpStart = () => {
  return {
    type: RATE_USER_GENERIC_START,
  };
};

export const rateUserGenericExpSuccess = (payload) => {
  return {
    type: RATE_USER_GENERIC_SUCCESS,
    payload,
  };
};

export const rateUserGenericExpFailed = (payload) => {
  return {
    type: RATE_USER_GENERIC_FAILURE,
    payload,
  };
};

export const postRateUserGenericExp = (payload) => async (dispatch) => {
  dispatch(rateUserGenericExpStart());
  const { data, error } = await rateUserGenericExp(payload);
  if (data && data?.statusCode === "90000") {
    dispatch(rateUserGenericExpSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(rateUserGenericExpFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const rateUserServicesExpStart = () => {
  return {
    type: RATE_USER_SERVICES_START,
  };
};

export const rateUserServicesExpSuccess = (payload) => {
  return {
    type: RATE_USER_SERVICES_SUCCESS,
    payload,
  };
};

export const rateUserServicesExpFailed = (payload) => {
  return {
    type: RATE_USER_SERVICES_FAILURE,
    payload,
  };
};

export const postRateUserServicesExp = (payload) => async (dispatch) => {
  dispatch(rateUserServicesExpStart());
  const { data, error } = await rateUserServiceExp(payload);
  if (data && data?.statusCode === "90000") {
    dispatch(rateUserServicesExpSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(rateUserServicesExpFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getUserRatedAllServicesStart = () => {
  return {
    type: GET_USER_RATED_ALL_SERVICES_START,
  };
};

export const getUserRatedAllServicesSuccess = (payload) => {
  return {
    type: GET_USER_RATED_ALL_SERVICES_SUCCESS,
    payload,
  };
};

export const getUserRatedAllServicesFailed = (payload) => {
  return {
    type: GET_USER_RATED_ALL_SERVICES_FAILURE,
    payload,
  };
};

export const fetchUserRatedAllServices = (userId) => async (dispatch) => {
  dispatch(getUserRatedAllServicesStart());
  const { data, error } = await getUserRatedAllServices(userId);
  if (data && data?.statusCode === "90000") {
    dispatch(getUserRatedAllServicesSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getUserRatedAllServicesFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getUserRatedGenericStart = () => {
  return {
    type: GET_USER_RATED_GENERIC_START,
  };
};

export const getUserRatedGenericSuccess = (payload) => {
  return {
    type: GET_USER_RATED_GENERIC_SUCCESS,
    payload,
  };
};

export const getUserRatedGenericFailed = (payload) => {
  return {
    type: GET_USER_RATED_GENERIC_FAILURE,
    payload,
  };
};

export const fetchUserRatedGeneric = (userId) => async (dispatch) => {
  dispatch(getUserRatedGenericStart());
  const { data, error } = await getUserRatedGeneric(userId);
  if (data && data?.statusCode === "90000") {
    dispatch(getUserRatedGenericSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getUserRatedGenericFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getUserActiveConnectionsStart = () => {
  return {
    type: GET_USER_ACTIVE_CONNECTIONS_START,
  };
};

export const getUserActiveConnectionsSuccess = (payload) => {
  return {
    type: GET_USER_ACTIVE_CONNECTIONS_SUCCESS,
    payload,
  };
};

export const getUserActiveConnectionsFailed = (payload) => {
  return {
    type: GET_USER_ACTIVE_CONNECTIONS_FAILURE,
    payload,
  };
};

export const fetchUserActiveConnections = (userId) => async (dispatch) => {
  dispatch(getUserActiveConnectionsStart());
  const { data, error } = await getUserActiveConnections(userId);
  if (data && data?.statusCode === "90000") {
    dispatch(getUserActiveConnectionsSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getUserActiveConnectionsFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getUserDeviceInfoStart = () => {
  return {
    type: GET_USERS_DEVICE_INFO_START,
  };
};

export const getUserDeviceInfoSuccess = (payload) => {
  return {
    type: GET_USERS_DEVICE_INFO_SUCCESS,
    payload,
  };
};

export const getUserDeviceInfoFailed = (payload) => {
  return {
    type: GET_USERS_DEVICE_INFO_FAILURE,
    payload,
  };
};

export const getUserDeviceInfoById = (userId) => async (dispatch) => {
  dispatch(getUserDeviceInfoStart());
  const { data, error } = await getUserDeviceInfo(userId);
  if (data && data?.statusCode === "90000") {
    dispatch(getUserDeviceInfoSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getUserDeviceInfoFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getUserIssueInfoStart = () => {
  return {
    type: GET_USERS_ISSUE_INFO_START,
  };
};

export const getUserIssueInfoSuccess = (payload) => {
  return {
    type: GET_USERS_ISSUE_INFO_SUCCESS,
    payload,
  };
};

export const getUserIssueInfoFailed = (payload) => {
  return {
    type: GET_USERS_ISSUE_INFO_FAILURE,
    payload,
  };
};

export const getUserIssueInfoById = (userId) => async (dispatch) => {
  dispatch(getUserIssueInfoStart());
  const { data, error } = await getUserIssueInfo(userId);
  if (data && data?.statusCode === "90000") {
    dispatch(getUserIssueInfoSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getUserIssueInfoFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};


export const getUserAppInfoStart = () => {
  return {
    type: GET_USERS_APP_INFO_START,
  };
};

export const getUserAppInfoSuccess = (payload) => {
  return {
    type: GET_USERS_APP_INFO_SUCCESS,
    payload,
  };
};

export const getUserAppInfoFailed = (payload) => {
  return {
    type: GET_USERS_APP_INFO_FAILURE,
    payload,
  };
};

export const getUserAppInfoById = (userId) => async (dispatch) => {
  dispatch(getUserAppInfoStart());
  const { data, error } = await getUserAppInfo(userId);
  if (data && data?.statusCode === "90000") {
    dispatch(getUserAppInfoSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getUserAppInfoFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getUserIpInfoStart = () => {
  return {
    type: GET_USERS_IP_INFO_START,
  };
};

export const getUserIpInfoSuccess = (payload) => {
  return {
    type: GET_USERS_IP_INFO_SUCCESS,
    payload,
  };
};

export const getUserIpInfoFailed = (payload) => {
  return {
    type: GET_USERS_IP_INFO_FAILURE,
    payload,
  };
};

export const getUserIpInfoById = (userId) => async (dispatch) => {
  dispatch(getUserIpInfoStart());
  const { data, error } = await getUserIpInfo(userId);
  if (data && data?.statusCode === "90000") {
    dispatch(getUserIpInfoSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getUserIpInfoFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getUserLocationInfoStart = () => {
  return {
    type: GET_USERS_LOCATION_INFO_START,
  };
};

export const getUserLocationInfoSuccess = (payload) => {
  return {
    type: GET_USERS_LOCATION_INFO_SUCCESS,
    payload,
  };
};

export const getUserLocationInfoFailed = (payload) => {
  return {
    type: GET_USERS_LOCATION_INFO_FAILURE,
    payload,
  };
};

export const getUserLocationInfoById = (userId) => async (dispatch) => {
  dispatch(getUserLocationInfoStart());
  const { data, error } = await getUserLocationInfo(userId);
  if (data && data?.statusCode === "90000") {
    dispatch(getUserLocationInfoSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getUserLocationInfoFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};