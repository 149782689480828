import styled, { keyframes } from "styled-components";

const bannerAnimation = keyframes`
  0% {left: 0px; top: 0px;}
  25%  {left: 200px; top: 0px;}
  100% {left: 0px; top: 0px;}
  `;

export const RatingsCardStyles = styled.section`
  padding: 1.2rem;
  background: white;
  box-shadow: 0 0 5px #e5e8e9;
  margin: 20px 0;

  label {
    width: 100%;
  }

  p {
    font-size: 0.875rem;
    color: #62646d;
    font-weight: 700;
    margin-bottom: 4px;
  }

  input {
    height: 40px;
  }
  textarea {
    resize: none;
  }
  input,
  textarea {
    border-radius: 5px;
    border: 1px solid #96c115;
    padding: 0.5rem;
    margin-right: 20px;
    outline: none;
    &:focus {
      border: 2px solid #7ca212;
    }
  }
  span,
  li {
    font-size: 0.875rem;
  }

  .select-container {
    display: flex;
    align-items: center;
  }
  .button-right {
    float: right;
  }
  .option-button {
    margin-top: 20px;
  }
`;

export const ProgressbarStyle = styled.section`
  width: 100%;
  background: #62646d;
  height: 20px;
  .indicator {
    height: 20px;
    width: ${({ $percent }) => ($percent ? `${$percent}%` : null)};
    background: #96c115;
`;

export const RatingsBannerStyle = styled.div`
  background: #96c115;
  position: absolute;
  top: 0;
  left: 0;
  height: 1 rem;
  margin-top: 3.7rem;
  z-index: 100;
  width: 100%;
  border-style: solid;
  border-color: #959190;
  animation-name: ${bannerAnimation};
  animation-duration: 5s;
  animation-iteration-count: once;

  .top {
    h2 {
      font-size: 1.2rem;
      line-height: 1.5rem;
      color: #090b17;
      font-style: normal;
      font-weight: bold;
    }
    .close {
      position: absolute;
      width: 0.6rem;
      right: 1rem;
      top: 0.2rem;
    }
    .close svg {
      fill: black;
      cursor: pointer;
      transition: fill ease-in-out 0.5s;
      &:hover {
        fill: #7ca212;
      }
    }
  }
  .content {
    font-size: 0.75rem;
    line-height: 1.5rem;
    color: #090b17;
    font-style: normal;
    font-weight: bold;
    width: 95%;
    padding: 1.5% 0;
    text-align: center;
  }
  .action-button {
    float: right;
    margin-bottom: 10px;
    margin-top: -30px;
  }
  @media (max-width: 768px) {
    .content {
      font-size: 0.6rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .action-button {
      display: inline-block;
      margin-bottom: 0;
      margin-top: 12px;
      font-size: 12px;
    }
  }
  @media (max-width: 640px) {
    .content {
      font-size: 0.45rem;
    }
    .action-button {
      margin-top: 10px;
      font-size: 8px;
    }
    button {
      padding: 8px;
    }
  }
`;
