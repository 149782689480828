import { useState, useRef } from "react";
import SortCard from "./SortCard";
import SortCardMoveHistory from "./SortCardMoveHistory";
import FilterItems from "./FilterItems";
import { SortFilterStyle } from "./SortFilter.Style";
import { Button } from "../../atoms";
import { useComponentVisible } from "../../../utils/customHooks/useComponentVisible";
import SearchFilter from "../SearchFilter/SearchFilter";
const SortFilterMessages = ({
  sortValue,
  setSortvalue,
  setStartDate,
  setEndDate,
  setSortParams,
  setSortOrder,
  setMessage,
  setHost,
  setUsername,
  setDeviceName,
  setPrincipalName,
  handleApply,
  type,
}) => {
  const cardRef = useRef(null);
  const [visible, setVisible] = useState(false);
  useComponentVisible(cardRef, setVisible);
  const handleVisibility = () => {
    setVisible(!visible);
  };

  const fields = [
    "Date (Earliest to Latest)",
    "Date (Latest to Earliest)",
    "Sender (A-Z)",
    "Sender (Z-A)",
  ];

  const fieldsMoveHistory = [
    "Move Date (Earliest to Latest)",
    "Move Date (Latest to Earliest)",
    "Device Name (A-Z)",
    "Device Name (Z-A)",
    "EMS Host (A-Z)",
    "EMS Host (Z-A)",
    "EMS Message (A-Z)",
    "EMS Message (Z-A)",
  ];

  return (
    <SortFilterStyle>
      {type === "deviceAssignment" ? (
        <div className="main">
          {" "}
          <div className="container date-filters">
            <SearchFilter
              setSearchQuery={setDeviceName}
              placeholder="Device Name"
            />
          </div>{" "}
          <div className="container date-filters">
            <SearchFilter
              setSearchQuery={setPrincipalName}
              placeholder="User Principal Name"
            />
          </div>
        </div>
      ) : (
        <div className="main">
          <div className="container" onClick={handleVisibility} ref={cardRef}>
            <div className="date-filters">
              <label>{"Sort By"}</label>
              {type === "moveHistory" ? (
                <SortCardMoveHistory
                  detailsList={fieldsMoveHistory}
                  title={fieldsMoveHistory[0]}
                  setFilterValue={setSortvalue}
                  filterValue={sortValue}
                  setSortParams={setSortParams}
                  setSortOrder={setSortOrder}
                  visible={visible}
                />
              ) : (
                <SortCard
                  detailsList={fields}
                  title={fields[0]}
                  setFilterValue={setSortvalue}
                  filterValue={sortValue}
                  setSortParams={setSortParams}
                  setSortOrder={setSortOrder}
                  visible={visible}
                />
              )}
            </div>
          </div>
          <div className="container date-selector">
            <FilterItems
              setFilterValue={setStartDate}
              labelTitle="Start Date"
            />
          </div>
          <div className="container date-selector">
            <FilterItems setFilterValue={setEndDate} labelTitle="End Date" />
          </div>
          {type === "moveHistory" ? (
            <>
              <div className="container date-filters">
                <SearchFilter
                  setSearchQuery={setMessage}
                  placeholder="EMS Message"
                />
              </div>
              <div className="container date-filters">
                <SearchFilter setSearchQuery={setHost} placeholder="EMS Host" />
              </div>
            </>
          ) : (
            <>
              <div className="container date-filters">
                <SearchFilter
                  setSearchQuery={setMessage}
                  placeholder="Message"
                />
              </div>
              <div className="container date-filters">
                <SearchFilter
                  setSearchQuery={setUsername}
                  placeholder="Username"
                />
              </div>
            </>
          )}

          <div className="container date-filters">
            <SearchFilter
              setSearchQuery={setDeviceName}
              placeholder="DeviceName"
            />
          </div>
        </div>
      )}
      {type !== "deviceAssignment" &&
        <div className="sent-messages-button">
          <Button fill="fill" onclick={handleApply}>
            Apply Filters
          </Button>
        </div>
      }
    </SortFilterStyle>
  );
};

export default SortFilterMessages;
