import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "../../atoms";
import { FormStyle, FieldStyle } from "./Form.style";
import {
  updateSharedStatusPatch,
} from "../../../Redux/Zte/Device/DeviceActions";

const UpdateshareStatusForm = ({ setOpen, isShared }) => {
  const { deviceId } = useParams();
  const dispatch = useDispatch();

  const sharedData = {
    deviceId: deviceId,
    isShared: !isShared,
  };

  const { deviceDetail } = useSelector(
    (state) => state.devices
  )
  const deviceName = deviceDetail?.deviceName
  
  const handleUpdateSharedStatus = () => {
    dispatch(updateSharedStatusPatch(sharedData));
    setOpen(false);
  };

  return (
    <FormStyle>
      <FieldStyle>
        <div className="button-style-top">
          <div className="buttons-div">
            <p>
              {isShared
                ? `Do you really want to stop sharing ${deviceName}?`
                : `Do you really want to  share ${deviceName}?`}
            </p>
          </div>
          <div className="buttons-div button-style-top">
            <div className="right">
            <Button fill="fill" onclick={handleUpdateSharedStatus}>
              Yes
              </Button>
            </div>
            <div className="right">
            <Button fill="fill" onclick={()=>setOpen(false)}>
              No
              </Button>
              </div>
          </div>
          
        </div>
      </FieldStyle>
    </FormStyle>
  );
};

export default UpdateshareStatusForm;
