import styled from "styled-components";

export const AdminCardStyles = styled.section`
  padding: 1.2rem;
  background: white;
  box-shadow: 0 0 5px #e5e8e9;
  margin: 2rem 0;
  overflow-x: scroll;

  label {
    width: 100%;
  }

  p {
    font-size: 0.875rem;
    color: #62646d;
    font-weight: 700;
    margin-bottom: 4px;
  }

  input {
    height: 40px;
    border-radius: 5px;
    border: 1px solid #96c115;
    padding: 0.5rem;
    margin-right: 20px;
    outline: none;
    &:focus {
      border: 2px solid #7ca212;
    }
  }

  .input-text {
    width: 280px;
  }
  .input-button {
    margin-top: 4px;
  }
  .button-style {
    margin-left: 4px;
    margin-top: 15px;
  }
  .button-style-dropdown {
    padding-top: 30px;
  }

  .button-style-ip {
    padding-top: 22px;
  }

  .dropdown-input {
    padding-top: 4px;
  }

  .no-heading-style {
    padding-top: 18px;
  }

  .check {
    margin-left: 10px;
    margin-top: -8px;
    width: 20px;
  }

  .admin-input {
    margin: 15px 0;
  }

  .group-container {
    display: flex;
    margin-bottom: 20px;
  }

  .wrapper {
    width: 280px;
    margin-right: 20px;
  }

  .select-btn,
  .options li,
  .options input,
  label,
  .heading-style {
    display: flex;
    align-items: center;
  }
  .user-checkbox {
    margin-top: 20px;
  }
  label {
    cursor: pointer;
  }

  .select-btn {
    padding: 0 20px;
    border-radius: 5px;
    justify-content: space-between;
    background: #f6f6f6;
    height: 40px;
    width: 280px;
  }

  span,
  li {
    font-size: 0.875rem;
  }
  .content {
    background: #f6f6f6;
    margin-top: 15px;
    padding: 12px;
    border-radius: 5px;
    font-size: 0.875rem;
    position: absolute;
    z-index: 10;
    border: 1px solid #e5e8e9;
  }

  .content .search {
    position: relative;
  }

  .search .icon {
    left: 10px;
    top: 14px;
    line-height: 53px;
    position: absolute;
    font-size: 1rem;
    color: #999;
  }

  .search input {
    height: 40px;
    width: 100%;
    font-size: 17px;
    outline: none;
    border-radius: 5px;
    padding: 0 15px 0 43px;
    border: 1px solid #96c115;
  }

  .content .options {
    margin-top: 10px;
    max-height: 250px;
    overflow-y: auto;
  }

  .options li,
  .label-input {
    height: 50px;
    padding: 0 13px;
    font-size: 16px;
    border-radius: 5px;
  }
  .options li:hover,
  .label-input:hover {
    background: #d3d3d3;
  }

  .table-style {
    margin-top: -30px;
  }

  .table-style-ems{
    display: flex;
    margin-top: -30px;
  }

  .table-style td {
    max-width: 150px;
    overflow-x: scroll;
  }
  .ztna-container {
    display: flex;
  }
  .input-service {
    margin-top: 30px;
  }
  .input-service-name {
    margin-top: 4px;
  }
  .button-groups {
    display: flex;
    gap: 20px;
  }
  .small-label {
    font-size: 0.8rem;
    // background:red;
    margin-top: -16px;
    font-weight: normal;
  }
  .image-container {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    margin-right: 10px;
    margin-top: 15px;
  }
  .remove-btn {
    margin-right: 20px;
  }
  .dropdown-group {
    margin-top: -4px;
    display: flex;
  }
`;
