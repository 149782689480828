import {
  GET_DEVICE_START,
  GET_DEVICE_FAILURE,
  GET_DEVICE_SUCCESS,
  GET_DEVICE_DETAIL_FAILURE,
  GET_DEVICE_DETAIL_START,
  GET_DEVICE_DETAIL_SUCCESS,
  GET_DEVICE_BASIC_FAILURE,
  GET_DEVICE_BASIC_SUCCESS,
  GET_DEVICE_BASIC_START,
  ADD_DEVICE_FAILURE,
  ADD_DEVICE_SUCCESS,
  ADD_DEVICE_START,
  ADD_DEVICE_UPDATE_START,
  ADD_DEVICE_UPDATE_FAILURE,
  ADD_DEVICE_UPDATE_SUCCESS,
  UPLOAD_DEVICES_FAILURE,
  UPLOAD_DEVICES_SUCCESS,
  UPLOAD_DEVICES_START,
  GET_SENT_MESSAGES_FAILURE,
  GET_SENT_MESSAGES_SUCCESS,
  GET_SENT_MESSAGES_START,
  GET_DEVICE_MOVEMENT_FAILURE,
  GET_DEVICE_MOVEMENT_SUCCESS,
  GET_DEVICE_MOVEMENT_START,
  DEVICE_ASSIGNMENT_SUGGESTIONS_FAILURE,
  DEVICE_ASSIGNMENT_SUGGESTIONS_SUCCESS,
  DEVICE_ASSIGNMENT_SUGGESTIONS_START,
  GET_LIST_EMS_GROUP_FAILURE,
  GET_LIST_EMS_GROUP_SUCCESS,
  GET_LIST_EMS_GROUP_START,
  GET_DEVICE_ASSIGNED_FAILURE,
  GET_DEVICE_ASSIGNED_SUCCESS,
  GET_DEVICE_ASSIGNED_START,
} from "./DeviceActionTypes";

const initialState = {
  devices: [],
  searchingDevices: false,
  searchingDevicesError: null,
  deviceDetail: {},
  fetchingDeviceDetail: false,
  fetchingDeviceDetailError: null,
  allDevicesBasic: {},
  fetchingAllDevicesBasic: false,
  fetchingAllDevicesBasicError: null,
  addDevicesError: null,
  addDevicesBasicStart: false,
  addDevicesUpdateError: null,
  addDevicesUpdateStart: false,
  uploadDevicesStart: false,
  uploadDevicesError: null,
  sentMessages: [],
  fetchingSentMessages: false,
  fetchingSentMessagesError: null,
  deviceMovement: [],
  fetchingDeviceMovement: false,
  fetchingDeviceMovementError: null,
  assignmentSuggestions: [],
  fetchingAssignmentSuggestions: false,
  fetchingAssignmentSuggestionsError: null,
  emsGroups: [],
  fetchingEmsGroups: false,
  fetchingEmsGroupsError: null,
  deviceAssigned: [],
  fetchingDeviceAssigned: false,
  fetchingDeviceAssignedError: null
};

const deviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEVICE_START:
      return {
        ...state,
        searchingDevices: true,
        searchingDevicesError: null,
        devices: [],
      };
    case GET_DEVICE_FAILURE:
      return {
        ...state,
        searchingDevices: false,
        searchingDevicesError: action.payload,
        devices: [],
      };
    case GET_DEVICE_SUCCESS:
      return {
        ...state,
        searchingDevices: false,
        searchingDevicesError: null,
        devices: action.payload,
      };
    case GET_DEVICE_DETAIL_START:
      return {
        ...state,
        fetchingDeviceDetail: true,
        fetchingDeviceDetailError: null,
        deviceDetail: {},
      };
    case GET_DEVICE_DETAIL_FAILURE:
      return {
        ...state,
        fetchingDeviceDetail: false,
        fetchingDeviceDetailError: action.payload,
        deviceDetail: {},
      };
    case GET_DEVICE_DETAIL_SUCCESS:
      return {
        ...state,
        fetchingDeviceDetail: false,
        fetchingDeviceDetailError: null,
        deviceDetail: action.payload.data,
      };
    case GET_DEVICE_BASIC_START:
      return {
        ...state,
        fetchingAllDevicesBasic: true,
        fetchingAllDevicesBasicError: null,
        allDevicesBasic: {},
      };
    case GET_DEVICE_BASIC_FAILURE:
      return {
        ...state,
        fetchingAllDevicesBasic: false,
        fetchingAllDevicesBasicError: action.payload,
        allDevicesBasic: {},
      };
    case GET_DEVICE_BASIC_SUCCESS:
      return {
        ...state,
        fetchingAllDevicesBasic: false,
        fetchingAllDevicesBasicError: null,
        allDevicesBasic: action.payload.data,
      };
    case ADD_DEVICE_START:
      return {
        ...state,
        addDevicesBasicStart: true,
        addDevicesError: null,
      };
    case ADD_DEVICE_FAILURE:
      return {
        ...state,
        addDevicesBasicStart: false,
        addDevicesError: action.payload,
      };
    case ADD_DEVICE_SUCCESS:
      return {
        ...state,
        addDevicesBasicStart: false,
        addDevicesError: null,
      };
    case ADD_DEVICE_UPDATE_START:
      return {
        ...state,
        addDevicesUpdateStart: true,
        addDevicesUpdateError: null,
      };
    case ADD_DEVICE_UPDATE_FAILURE:
      return {
        ...state,
        addDevicesUpdateStart: false,
        addDevicesUpdateError: action.payload,
      };
    case ADD_DEVICE_UPDATE_SUCCESS:
      return {
        ...state,
        addDevicesUpdateStart: false,
        addDevicesUpdateError: null,
      };
    case UPLOAD_DEVICES_START:
      return {
        ...state,
        uploadDevicesStart: true,
        uploadDevicesError: null,
      };
    case UPLOAD_DEVICES_FAILURE:
      return {
        ...state,
        uploadDevicesStart: false,
        uploadDevicesError: action.payload,
      };
    case UPLOAD_DEVICES_SUCCESS:
      return {
        ...state,
        uploadDevicesStart: false,
        uploadDevicesError: null,
      };
    case GET_SENT_MESSAGES_START:
      return {
        ...state,
        fetchingSentMessages: true,
        fetchingSentMessagesError: null,
        sentMessages: {},
      };
    case GET_SENT_MESSAGES_FAILURE:
      return {
        ...state,
        fetchingSentMessages: false,
        fetchingSentMessagesError: action.payload,
        sentMessages: {},
      };
    case GET_SENT_MESSAGES_SUCCESS:
      return {
        ...state,
        fetchingSentMessages: false,
        fetchingSentMessagesError: null,
        sentMessages: action.payload,
      };
    case GET_DEVICE_MOVEMENT_START:
      return {
        ...state,
        fetchingDeviceMovement: true,
        fetchingDeviceMovementError: null,
        deviceMovement: {},
      };
    case GET_DEVICE_MOVEMENT_FAILURE:
      return {
        ...state,
        fetchingDeviceMovement: false,
        fetchingDeviceMovementError: action.payload,
        deviceMovement: {},
      };
    case GET_DEVICE_MOVEMENT_SUCCESS:
      return {
        ...state,
        fetchingDeviceMovement: false,
        fetchingDeviceMovementError: null,
        deviceMovement: action.payload,
      };
    case DEVICE_ASSIGNMENT_SUGGESTIONS_START:
      return {
        ...state,
        fetchingAssignmentSuggestions: true,
        fetchingAssignmentSuggestionsError: null,
        assignmentSuggestions: {},
      };
    case DEVICE_ASSIGNMENT_SUGGESTIONS_FAILURE:
      return {
        ...state,
        fetchingAssignmentSuggestions: false,
        fetchingAssignmentSuggestionsError: action.payload,
        assignmentSuggestions: {},
      };
    case DEVICE_ASSIGNMENT_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        fetchingAssignmentSuggestions: false,
        fetchingAssignmentSuggestionsError: null,
        assignmentSuggestions: action?.payload,
      };
    case GET_LIST_EMS_GROUP_START:
      return {
        ...state,
        fetchingEmsGroups: true,
        fetchingEmsGroupsError: null,
        emsGroups: {},
      };
    case GET_LIST_EMS_GROUP_FAILURE:
      return {
        ...state,
        fetchingEmsGroups: false,
        fetchingEmsGroupssError: action.payload,
        emsGroups: {},
      };
    case GET_LIST_EMS_GROUP_SUCCESS:
      return {
        ...state,
        fetchingEmsGroups: false,
        fetchingEmsGroupssError: null,
        emsGroups: action?.payload,
      };
      case GET_DEVICE_ASSIGNED_START:
        return {
          ...state,
          fetchingDeviceAssigned: true,
          fetchingDeviceAssignedError: null,
          deviceAssigned: {},
        };
      case GET_DEVICE_ASSIGNED_FAILURE:
        return {
          ...state,
          fetchingDeviceAssigned: false,
          fetchingDeviceAssignedError: action.payload,
          deviceAssigned: {},
        };
      case  GET_DEVICE_ASSIGNED_SUCCESS:
        return {
          ...state,
          fetchingDeviceAssigned: false,
          fetchingDeviceAssignedError: null,
          deviceAssigned: action?.payload.data,
        };

    default:
      return state;
  }
};

export default deviceReducer;
