import API from "../axio";
import APIForm from "../axiosForm";

export const searchByDevices = async (currentPage, searchquery) => {
  const searchQuery = searchquery || "";
  try {
    const { data } = await API.get(
      `device/list?PageNumber=${currentPage}&PageSize=20&Name=${searchQuery}`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};
export const getDeviceDetail = async (id) => {
  try {
    const { data } = await API.get(`device/detail?deviceId=${id}`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getAllDevicesBasic = async () => {
  try {
    const { data } = await API.get(`/device/list-basic`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const addDevice = async (payload) => {
  try {
    const { data } = await API.post(`/device/add`, payload);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const uploadDevices = async (file) => {
  try {
    const { data } = await APIForm.post(`/device/upload`, file);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const initiateDeviceUpdate = async (payload) => {
  try {
    const { data } = await API.post(`/device/initiate-update`, payload);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const deviceSendMessage = async (payload) => {
  try {
    const { data } = await API.post(`/device/send-message`, payload);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getSentMessages = async (
  currentPage,
  startDate,
  endDate,
  sortOrder,
  sortParams,
  message,
  username,
  deviceName
) => {
  try {
    const { data } = await API.get(
      `device/sent-messages?PageNumber=${currentPage}&PageSize=10&StartDate=${startDate}&EndDate=${endDate}&SortOrder=${sortOrder}&SortParams=${sortParams}&Message=${message}&Username=${username}&DeviceName=${deviceName}`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getDeviceMovement = async (
  currentPage,
  startDate,
  endDate,
  sortOrder,
  sortParams,
  host,
  message,
  deviceName
) => {
  try {
    const { data } = await API.get(
      `device/movement-history?PageNumber=${currentPage}&PageSize=10&StartDate=${startDate}&EndDate=${endDate}&SortOrder=${sortOrder}&SortParams=${sortParams}&EMSMessage=${message}&EMSHost=${host}&DeviceName=${deviceName}`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const moveDevices = async (payload) => {
  try {
    const { data } = await API.post(
      `device/move`, payload
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
}

export const getAssignmentSuggestions = async ({currentPage, deviceName, userPrincipalName}) => {
  try {
    const { data } = await API.get(`/device/assignment-suggestions?PageNumber=${currentPage}&PageSize=10&DeviceName=${deviceName}&PageSize=10&UserPrincipalName=${userPrincipalName}`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getListEmsGroups = async () => {
  try {
    const { data } = await API.get(`/device/list-ems-groups`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const addEmsGroups = async (payload) => {
  try {
    const { data } = await API.post(
      `device/add-ems-group`, payload
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getDeviceAssignedUsers = async (id) => {
  try {
    const { data } = await API.get(`device/assigned-users?deviceId=${id}`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const updateSharedStatus = async (payload) => {
  try {
    const { data } = await API.patch(`device/update-shared-status`, payload);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const updateSuggestion = async (payload) => {
  try {
    const { data } = await API.post(
      `device/update-suggestion`, payload
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
}
