import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "../../atoms";
import { FormStyle, FieldStyle } from "./Form.style";
import SearchDropDown from "../AdminCard/SearchDropdown";
import {
  getListofEmsgroups,
  moveDevicesPost,
} from "../../../Redux/Zte/Device/DeviceActions";
import { useEffect, useState } from "react";

const MoveDeviceForm = ({ setOpen, data }) => {
  const { deviceId } = useParams();
  const dispatch = useDispatch();
  const [groupId, setGroupId] = useState();

  const moveData = {
    devicesToMove: [deviceId],
    groupId: groupId,
  };

  useEffect(() => {
    dispatch(getListofEmsgroups());
  }, [dispatch]);

  const handleMoveDevice = () => {
    dispatch(moveDevicesPost(moveData));
    setOpen(false);
  };

  const { emsGroups, fetchingEmsGroups } = useSelector(
    (state) => state.devices
  );


  return (
    <FormStyle>
      <FieldStyle>
        <SearchDropDown
          title={"EMS Groups"}
          type="select"
          group={"groups"}
          data={emsGroups?.data}
          setData={setGroupId}
          loading={fetchingEmsGroups}
        />
        <div className="button-style-top">

        <Button fill="fill" onclick={handleMoveDevice} disabled={!groupId}>
          Submit
          </Button>
          </div>
      </FieldStyle>
    </FormStyle>
  );
};

export default MoveDeviceForm;
